@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600;700;800&display=swap");

:root {
  --menuLogo: url(https://res.cloudinary.com/dfpleoyv6/image/upload/v1616622599/menu_light_q9mist.svg)
    no-repeat;
  --mainLogo: url(https://res.cloudinary.com/dfpleoyv6/image/upload/v1616016154/main_logoLight_ieomku.png)
    no-repeat;

  /* Colors */
  --blue: #224cff;
  --text: black;
  --textMirror: white;
  --background: white;
  --backgroundNavbar: black;
}
* { 
  padding:0;
  margin:0;
}
body {
  text-align: center;
  background-color: var(--background);
  margin: 0;
  padding: 40px;
  color: var(--text);

  font-family: "Bitter", serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.my-project-links a {
  font-family: "Raleway", sans-serif;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 3.5em;
}

h3 {
  font-size: 1.5em;
}

h1,
h2 {
  font-weight: 800;
}

h3,
h4,
h5 {
  font-weight: 600;
}

.text-blue,
.text-blue li {
  color: var(--blue);
  font-family: "Bitter", serif;
}

.my-tools {
  display: flex;
  margin-bottom: 15px;
}

.main {
  height: 83vh;
  display: flex;
  justify-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.home {
  margin: auto 15vw auto auto;
}

.home h1 {
  position: absolute;
  top: 50%;
  right: 9%;
  width: 100%;
}

.home-background img {
  width: 500px;
  height: auto;
  animation: move 10s linear infinite;
}
@keyframes move {
  0% {
    transform: scale(1) translate(1vw, 1vh);
  }
  38% {
    transform: scale(0.8, 1) translate(10vw, 5vh) rotate(160deg);
  }
  40% {
    transform: scale(0.8, 1) translate(10vw, 5vh) rotate(160deg);
  }
  78% {
    transform: scale(0.8, 1) translate(10vw, 5vh) rotate(-20deg);
  }
  80% {
    transform: scale(0.8, 1) translate(10vw, 5vh) rotate(-20deg);
  }
  100% {
    transform: scale(1) translate(1vw, 1vh);
  }
}

.my-div-contact {
  margin-right: 15vw;
}

#example-collapse-text{
  margin-top: 10px;
}

.collapsedNavbar {
  display: flex;
  justify-content: center;
  background-color: var(--backgroundNavbar);
  border-radius: 15px;
  position: static;
  margin-bottom: 30px;
  
}

.my-navText {
  color: var(--textMirror);
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  border: 3px solid var(--backgroundNavbar);
  margin: 0 35px;
  font-size: 1.2em;
}

.my-navText:hover {
  border-color: var(--blue);
  color: var(--textMirror);
  transition: 1.3s;
}

.my-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.navbar-logo {
  background: var(--mainLogo);
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
  border: none;
  margin-top: 5px;
}

.navbar-main,
.navbar-main:hover,
.navbar-main:focus {
  padding: 0 auto;
  border: none;
  background: var(--menuLogo);
  background-size: 30px 30px;
  height: 30px;
  width: 30px;
}

.my-tools li::before {
  content: "#";
}

.my-tools ul {
  list-style: none;
}

#first-tags {
  padding: 0;
}
.my-tech {
  padding: 1px 5px;
}


.footer {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.go-top {
  background-color: var(--blue);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.go-top:hover {
  font-weight: 700;
}

.column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.my-projects {
  margin: 120 auto;
}

.my-project-links {
  display: flex;
  max-width: 360px;
  font-weight: 800;
  font-size: 1.1em;
  margin-bottom: 5px;
}
.my-project-links a {
  margin-right: 10px;
}
.my-project-links a:hover {
  background-color: var(--blue);
  color: white;
  text-decoration: none;
  border-radius: 2px;
  transition: 0.9s;
  padding: 0px 10px;
}
.my-project-tags {
  display: flex;
  flex-wrap: wrap;
}
.my-project-tags a {
  margin-right: 6px;
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 0;
  color:var(--blue)
}
.my-project-tags a::before {
  content: "#";
}

.my-contact {
  max-width: none;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.main .footer,
.my-error .footer {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}
.my-project-image {
  text-align: center;
}

.my-project-image img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.my-card-project {
  height: 100%;
}

.about{
height: 100vh;
display: flex;
flex-direction: column;
justify-content: space-evenly;
}

#intern-about{
  max-width: 1000px;
}

.rest-about{
  margin-top: 20px;
}

.rest-about li{
  margin-left: 35px;
}

.contact-me {
  box-shadow: 20px 20px 60px #fdd1b8, -20px -20px 60px #ffffff;
  padding: 15px;
  border-radius: 15px;
  border: 3px solid var(--blue);
  margin-bottom:35px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 1.2em;
}

.my-contact a {
  color: var(--blue);
  height: auto;
}

#second-tags {
  padding-left: 15px;
}
/* Mobile media */
@media (max-width: 900px) {
  body {
    padding: 20px 25px;
    transition: 1.5s;
  }

  h1 {
    font-size: 3.3em;
    transition: 1.5s;
  }
  .home,
  .my-div-contact {
    margin: auto 0 auto auto;
  }

  .collapsedNavbar {
    flex-direction: column;
  }

  .go-top {
    margin-top: 50px;
  }

  .home-background img {
    width: 300px;
  }

  @keyframes move {
    0% {
      transform: scale(1) translate(-20vw, 5vh);
    }
    38% {
      transform: scale(0.8, 1) translate(-20vw, 5vh) rotate(160deg);
    }
    40% {
      transform: scale(0.8, 1) translate(-20vw, 5vh) rotate(160deg);
    }
    78% {
      transform: scale(0.8, 1) translate(-20vw, 5vh) rotate(-20deg);
    }
    80% {
      transform: scale(0.8, 1) translate(-20vw, 5vh) rotate(-20deg);
    }
    100% {
      transform: scale(1) translate(-20vw, 5vh);
    }
  }

  section:first-of-type {
    padding-top: 30px;
  }

  .my-card-project {
    margin-bottom: 30px;
  }

  
}

@media (max-width: 900px) and (max-height: 1000px) {
  @keyframes move {
    0% {
      transform: scale(1) translate(-20vw, 5vh);
    }
    38% {
      transform: scale(0.8, 1) translate(-20vw, 3vh) rotate(160deg);
    }
    40% {
      transform: scale(0.8, 1) translate(-20vw, 3vh) rotate(160deg);
    }
    78% {
      transform: scale(0.8, 1) translate(-20vw, 3vh) rotate(-20deg);
    }
    80% {
      transform: scale(0.8, 1) translate(-20vw, 3vh) rotate(-20deg);
    }
    100% {
      transform: scale(1) translate(-20vw, 5vh);
    }
  }
}

@media (min-width: 500px) and (orientation: landscape) {
  .collapsedNavbar {
    flex-direction: row;
  }
}

@media (max-width: 1050px) and (orientation: landscape) {
  .my-card-project {
    margin-bottom: 70px;
  }

  section:first-of-type {
    margin-top: 30px;
  }
}

@media (max-width: 600px) and (max-height: 655px) {
  h1,
  h2 {
    font-size: 2.5em;
  }
}

@media (max-width: 290px) {

  .home-background img {
    width: 200px;
  }
}

@media (max-width: 500px) {
  .about {
    height: auto;
  }
  h3 {
    font-size: 1.3em;
  }

  .my-contact {
    flex-direction: column;
  }
}

/* Ipad */
@media (max-width: 1050px) and (min-height: 900px) {
  h1 {
    font-size: 3.2em;
  }
  section:first-of-type {
    margin-top: 30px;
  }
}
